import { createContext, Dispatch, FC, useContext, useReducer } from 'react'
import reducer from './reducer'
import { AnalysisActionProps, AnalysesStateProps } from './types'

export const initialState: AnalysesStateProps = {
    data: [],
    isLoading: true,
}

export const AnalysesState = createContext<AnalysesStateProps>(initialState)
export const AnalysesDispatch = createContext<Dispatch<AnalysisActionProps>>(
    () => null,
)
export const useAnalysesState = () => useContext(AnalysesState)
export const useAnalysesDispatch = () => useContext(AnalysesDispatch)

export const AnalysesProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
        () => initialState,
    )

    return (
        <AnalysesState.Provider value={state}>
            <AnalysesDispatch.Provider value={dispatch}>
                {children}
            </AnalysesDispatch.Provider>
        </AnalysesState.Provider>
    )
}
