import { LocationProps } from 'providers/Locations/types'

export enum UserActions {
    add = 'ADD',
    fetch = 'FETCH',
    remove = 'REMOVE',
    update = 'UPDATE',
    updateCount = 'UPDATE_COUNT',
    updateLoading = 'UPDATE_LOADING',
}

export type SubscriptionType = {
    type: number
    members: number
    dateStarted: Date | null
    dateEnd: Date | null
    premiumGrant: 'internal' | 'official'
}

export type UserProps = {
    allowUpdates: boolean
    acceptCookies: boolean
    createdAt: Date
    currentPassword?: string
    device?: string
    defaultOrganization: LocationProps
    email: string
    firstname: string
    id: string
    image: string
    internal: boolean
    isLoading?: boolean
    isLoggedIn?: boolean
    issues: string
    language: string
    lastActive: Date
    lastname: string
    newPassword?: string
    notified: boolean
    organizations: Array<Partial<LocationProps>>
    password?: string
    role: string
    status: string
    subscription: SubscriptionType
    tester: boolean
    theme: string
    username: string
    userToken: string
    userTokenExpires: Date
    verified: boolean
}

export type UserActionProps =
    | { type: UserActions.add; payload: UserProps }
    | { type: UserActions.fetch; payload: Array<UserProps> }
    | { type: UserActions.remove; payload: string }
    | { type: UserActions.update; payload: UserProps }
    | { type: UserActions.updateLoading; payload: boolean }
    | { type: UserActions.updateCount; payload: number }

export type UsersStateProps = {
    data: Array<UserProps>
    isLoading: boolean
    count: number
}
