export type ThemeType = typeof themes.light

const t = {
    font: {
        family: {
            default: '"Manrope", sans-serif',
            header: '"Inter", Manrope, sans-serif',
        },
        sizes: {
            h1: '2.71em',
            h2: '2.29em',
            h3: '1.71em',
            h4: '1.29em',
            h5: '1em',
            h6: '0.71em',
            large: '1.43em',
            medium: '1.14em',
            normal: '16px',
            small: '0.86em',
            tiny: '0.71em',
        },
    },
    indexes: {
        navigation: '100',
        popup: '200',
        tooltip: '300',
    },
    radius: {
        large: '20px',
        medium: '10px',
        round: '100px',
        small: '5px',
    },
}

const themes: any = {
    light: {
        type: 'LIGHT',
        colors: {
            danger: {
                c100: '#FFF9F7',
                c200: '#FFCDBC',
                c300: '#FF9E84',
                c400: '#FF734E',
                c500: '#FF4716',
                c600: '#CE3208',
                c700: '#AD2400',
                c800: '#691701',
                c900: '#290900',
            },
            warning: {
                c100: '#FFFCF7',
                c200: '#FFE7BE',
                c300: '#FFD284',
                c400: '#FFB83D',
                c500: '#FFAA16',
                c600: '#D28909',
                c700: '#AD6E00',
                c800: '#6C4500',
                c900: '#291A00',
            },
            success: {
                c100: '#F3FAF6',
                c200: '#B2FED3',
                c300: '#77E6A7',
                c400: '#2BDC78',
                c500: '#12D165',
                c600: '#08A04A',
                c700: '#007C36',
                c800: '#00461F',
                c900: '#001D0D',
            },
            brand: {
                c100: '#FAFBFF',
                c200: '#BCD5FD',
                c300: '#7FA5E2',
                c400: '#3D7BDB',
                c500: '#2263C9',
                c600: '#13489C',
                c700: '#093273',
                c800: '#031E48',
                c900: '#010B1B',
            },
            neutral: {
                c100: '#FBFBFB',
                c200: '#D2D8E0',
                c300: '#ADB4C0',
                c400: '#738097',
                c500: '#4C5D78',
                c600: '#303F58',
                c700: '#1C2A41',
                c800: '#0E192B',
                c900: '#070F1B',
            },
            sixS: {
                s1: '#FFA600',
                s2: '#68F287',
                s3: '#71D0E1',
                s4: '#9099EB',
                s5: '#FD8AB1',
                s6: '#E7636C',
            },
        },
        shadows: {
            default: '0px 5px 10px rgb(71, 82, 93, 0.05)',
            light: '0px 3px 10px rgba(71, 82, 93, 0.08)',
            wide: '0px 10px 20px rgba(71, 82, 93, 0.125)',
        },
        ...t,
    },
    dark: {
        type: 'DARK',
        colors: {
            danger: {
                c100: '#290900',
                c200: '#691701',
                c300: '#AD2400',
                c400: '#CE3208',
                c500: '#FF4716',
                c600: '#FF734E',
                c700: '#FF9E84',
                c800: '#FFCDBC',
                c900: '#FFF9F7',
            },
            warning: {
                c100: '#291A00',
                c200: '#6C4500',
                c300: '#AD6E00',
                c400: '#D28909',
                c500: '#FFAA16',
                c600: '#FFB83D',
                c700: '#FFD284',
                c800: '#FFE7BE',
                c900: '#FFFCF7',
            },
            success: {
                c100: '#001D0D',
                c200: '#00461F',
                c300: '#007C36',
                c400: '#08A04A',
                c500: '#12D165',
                c600: '#2BDC78',
                c700: '#77E6A7',
                c800: '#B2FED3',
                c900: '#F3FAF6',
            },

            brand: {
                c100: '#010B1B',
                c200: '#031E48',
                c300: '#093273',
                c400: '#13489C',
                c500: '#2263C9',
                c600: '#3D7BDB',
                c700: '#7FA5E2',
                c800: '#BCD5FD',
                c900: '#FAFBFF',
            },
            neutral: {
                c100: '#070F1B',
                c200: '#0E192B',
                c300: '#1C2A41',
                c400: '#303F58',
                c500: '#4C5D78',
                c600: '#738097',
                c700: '#ADB4C0',
                c800: '#D2D8E0',
                c900: '#FBFBFB',
            },
            sixS: {
                s1: '#FFA600',
                s2: '#68F287',
                s3: '#71D0E1',
                s4: '#9099EB',
                s5: '#FD8AB1',
                s6: '#E7636C',
            },
        },
        shadows: {
            default: '0px 5px 10px rgb(0, 0, 0, 0.5)',
            light: '0px 3px 10px rgba(0, 0, 0, 0.7)',
            wide: '0px 10px 20px rgba(0, 0, 0, 0.9)',
        },
        ...t,
    },
}

const theme: typeof themes.light = (theme: string) => themes[theme]
export default theme
