import { Canvg } from 'canvg'
import html2canvas from 'html2canvas'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'reset.css'

import 'fonts/stylesheet.css'
import { LocationsProvider } from 'providers/Locations'
import { MeProvider } from 'providers/Me'
import { ReportsProvider } from 'providers/Reports'
import { WorkspacesProvider } from 'providers/Workspaces'

import App from './App'

window.html2canvas = html2canvas
window.canvg = Canvg

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <MeProvider>
                <WorkspacesProvider>
                    <LocationsProvider>
                        <ReportsProvider>
                            <App />
                        </ReportsProvider>
                    </LocationsProvider>
                </WorkspacesProvider>
            </MeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root'),
)
