import { createContext, Dispatch, FC, useContext, useReducer } from 'react'
import reducer from './reducer'
import { WorkspaceActionProps, WorkspacesStateProps } from './types'

export const initialState: WorkspacesStateProps = {
    data: [],
    isLoading: true,
}

export const WorkspacesState = createContext<WorkspacesStateProps>(initialState)
export const WorkspacesDispatch = createContext<Dispatch<WorkspaceActionProps>>(
    () => null,
)
export const useWorkspacesState = () => useContext(WorkspacesState)
export const useWorkspacesDispatch = () => useContext(WorkspacesDispatch)

export const WorkspacesProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
        () => initialState,
    )

    return (
        <WorkspacesState.Provider value={state}>
            <WorkspacesDispatch.Provider value={dispatch}>
                {children}
            </WorkspacesDispatch.Provider>
        </WorkspacesState.Provider>
    )
}
