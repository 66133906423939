import { filter } from 'lodash'
import {
    AnalysesStateProps,
    AnalysisActionProps,
    AnalysisActions,
} from './types'

const reducer = (state: AnalysesStateProps, action: AnalysisActionProps) => {
    switch (action.type) {
        case AnalysisActions.fetch:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }

        case AnalysisActions.add:
            return {
                ...state,
                data: [action.payload, ...state.data],
                isLoading: false,
            }

        case AnalysisActions.remove:
            return {
                ...state,
                data: filter(state.data, value => value?.id !== action.payload),
                isLoading: false,
            }
        case AnalysisActions.updateLoading:
            return {
                ...state,
                isLoading: action.payload,
            }

        case AnalysisActions.updateCount:
            return {
                ...state,
                count: action.payload,
            }
        default:
            return state
    }
}

export default reducer
