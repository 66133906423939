import Cookie from 'js-cookie'
import { filter, groupBy, map, reduce, round } from 'lodash'
import { DateTime } from 'luxon'
import styled from 'styled-components/macro'

import { DasboardReportDataProps } from 'modules/audit/pages/Dashboard/Reports'
import { AnalysisProps, AnalysisScoreProps } from 'providers/Analyses/types'
import { AreaProps } from 'providers/Areas/types'
import { AuditProps, AuditScoreProps } from 'providers/Audits/types'
import { LocationProps, LocationShiftProps } from 'providers/Locations/types'
import { TaskProps } from 'providers/Tasks/types'
import { UserProps } from 'providers/Users/types'
import {
    ActivityProps,
    KeyEventProps,
    PointProps,
    WorkspaceProps,
} from 'providers/Workspaces/types'

import DoneIconPng from 'icons/done.png'
import { ReactComponent as DoneIcon } from 'icons/done.svg'
import { ReactComponent as HighIcon } from 'icons/high.svg'
import InProgressIconPng from 'icons/inProgress.png'
import { ReactComponent as InProgressIcon } from 'icons/inProgress.svg'
import { ReactComponent as LaboratoryIcon } from 'icons/laboratory.svg'
import { ReactComponent as LowIcon } from 'icons/low.svg'
import { ReactComponent as MediumIcon } from 'icons/medium.svg'
import { ReactComponent as OfficeIcon } from 'icons/office.svg'
import { ReactComponent as ProductionIcon } from 'icons/production.svg'
import { ReactComponent as SafetyIcon } from 'icons/safety.svg'
import TodoIconPng from 'icons/todo.png'
import { ReactComponent as TodoIcon } from 'icons/todo.svg'
import ToReviewIconPng from 'icons/toReview.png'
import { ReactComponent as ToReviewIcon } from 'icons/toReview.svg'

import theme from 'theme'

export const getHeaders = () => {
    const token = localStorage.getItem('token') as string
    return {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-access-token': token,
    }
}

export const randomRange = (min: number, max: number) => {
    return Math.round(Math.random() * (max - min) + min)
}

export const getElapseTime = (date: DateTime): string => {
    const dateDiff = DateTime.now().diff(date, [
        'years',
        'months',
        'weeks',
        'days',
        'hours',
        'minutes',
    ])

    //years
    if (Math.abs(dateDiff.years)) {
        if (dateDiff.years < 0) {
            return `Due in ${Math.abs(round(dateDiff.years))} year${
                dateDiff.years > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.years)} year${
            dateDiff.years > 1 ? 's' : ''
        } ago`
    }

    //months
    else if (Math.abs(dateDiff.months)) {
        if (dateDiff.months < 0) {
            return `Due in ${Math.abs(round(dateDiff.months))} month${
                dateDiff.months > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.months)} month${
            dateDiff.months > 1 ? 's' : ''
        } ago`
    }

    //weeks
    else if (Math.abs(dateDiff.weeks)) {
        if (dateDiff.weeks < 0) {
            return `Due in ${Math.abs(round(dateDiff.weeks))} week${
                dateDiff.weeks > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.weeks)} week${
            dateDiff.weeks > 1 ? 's' : ''
        } ago`
    }

    //days
    else if (Math.abs(dateDiff.days)) {
        if (dateDiff.days < 0) {
            return `Due in ${Math.abs(round(dateDiff.days))} day${
                dateDiff.days > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.days)} day${dateDiff.days > 1 ? 's' : ''} ago`
    } else if (Math.abs(dateDiff.hours)) {
        if (dateDiff.hours < 0) {
            return `Due in ${Math.abs(round(dateDiff.hours))} hour${
                dateDiff.hours > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.hours)} hour${
            dateDiff.hours > 1 ? 's' : ''
        } ago`
    }

    //minutes
    else if (Math.abs(dateDiff.minutes)) {
        if (dateDiff.minutes < 0) {
            return `Due in ${Math.abs(round(dateDiff.minutes))} minute${
                dateDiff.minutes > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.minutes)} minute${
            dateDiff.minutes > 1 ? 's' : ''
        } ago`
    }

    //seconds
    else if (Math.abs(dateDiff.seconds)) {
        if (dateDiff.seconds < 0) {
            return `Due in ${Math.abs(round(dateDiff.seconds))} second${
                dateDiff.seconds > 1 ? 's' : ''
            }`
        }
        return `${round(dateDiff.seconds)} second${
            dateDiff.seconds > 1 ? 's' : ''
        } ago`
    } else {
        return ''
    }
}

export type LimitTextLengthProps = {
    text: string
    limit: number
    hasEllipsis?: boolean
}

export const limitTextLength = ({
    text,
    limit,
    hasEllipsis = false,
}: LimitTextLengthProps) => {
    if (text.length > limit) {
        return `${text.substring(0, limit)}${hasEllipsis && '...'}`
    }

    return text
}

export const formatScores = (data: any): Array<AnalysisScoreProps> => {
    return map(data, value => {
        return formatScore(value)
    })
}

export const formatScore = (value: any): AnalysisScoreProps => {
    return {
        point: {
            id: value.point._id,
            name: value.point.name,
        },
        category: {
            id: value.category._id,
            name: value.category.name,
        },
        score: value.score,
    }
}

export const formatAnalyses = (data: any): Array<AnalysisProps> => {
    return map(data, value => {
        return formatAnalysis(value)
    })
}

export const formatAnalysis = (value: any) => {
    return {
        id: value._id,
        date: DateTime.fromISO(value.createdAt).toLocal(),
        createdAt: DateTime.fromISO(value.createdAt).toLocal(),
        createdBy: value.createdBy,
        workspace: value.workspace,
        location: value.location,
        scores: formatScores(value.scores),
        shift: value.shift,
    }
}

export const formatPoint = (value: any): PointProps => {
    return {
        createdAt: value?.createdAt,
        description: value?.description,
        id: value?._id,
        name: value?.name,
    }
}

export const formatPoints = (data: any): Array<PointProps> => {
    return map(data, value => {
        return formatPoint(value)
    })
}

export const formatKeyEvent = (value: any): KeyEventProps => {
    return {
        date: value?.date,
        description: value?.description,
        id: value?._id,
        name: value?.name,
    }
}

export const formatKeyEvents = (data: any): Array<KeyEventProps> => {
    return map(data, value => {
        return formatKeyEvent(value)
    })
}

export const formatWorkspace = (value: any): WorkspaceProps => {
    return {
        createdAt: value?.createdAt,
        description: value?.description,
        id: value?._id,
        name: value?.name,
        createdBy: value?.createdBy,
        location: value?.location,
        points: formatPoints(value?.points),
        events: formatKeyEvents(value?.events),
        activities: formatActivities(value?.activities),
    }
}

export const formatWorkspaces = (data: any): Array<WorkspaceProps> => {
    return map(data, value => {
        return formatWorkspace(value)
    })
}

export const formatArea = (value: any): AreaProps => {
    return {
        createdAt: value?.createdAt,
        description: value?.description,
        id: value?._id,
        image: value?.image,
        status: value?.status,
        title: value?.title,
        type: value?.type,
        createdBy: {
            id: value?.created_by?._id,
            email: value?.created_by?.email,
        },
        location: {
            id: value?.location?._id,
            name: value.location?.name,
            description: value?.location?.description,
            status: value?.location?.status,
        },
    }
}

export const formatAreas = (data: any): Array<AreaProps> => {
    return map(data, value => {
        return formatArea(value)
    })
}

export const formatActivity = (value: any) => {
    return {
        id: value?._id,
        name: value?.name,
        color: value?.color,
        description: value?.description,
        isDefault: value?.isDefault,
    }
}

export const formatActivities = (data: any): Array<ActivityProps> => {
    return map(data, value => {
        return formatActivity(value)
    })
}

const formatShift = (data: any): LocationShiftProps => {
    return {
        id: data?._id,
        name: data?.name,
        start: data?.start,
        end: data?.end,
    }
}
export const formatLocation = (value: any): LocationProps => {
    return {
        createdAt: value?.createdAt,
        createdBy: {
            id: value?.created_by?._id,
            email: value?.created_by?.email,
            firstname: value?.created_by?.first_name,
            lastname: value?.created_by?.last_name,
            image: value?.created_by?.image_url,
            subscription: value?.subscription,
        },
        description: value?.description,
        id: value?._id,
        image: value?.image,
        is6s: value?.is6s,
        name: value?.name,
        status: value?.status,
        members: map(value?.members, member => formatUser(member)),
        targetScore: value?.targetScore,
        taskOrder: value?.taskOrder,
        audit: {
            tasks: value?.audit?.tasks,
            audits: value?.audit?.audits,
            areas: value?.audit?.areas,
        },
        mma: {
            default: value?.mma?.default,
            shifts: map(value?.mma?.shifts, shift => formatShift(shift)),
            samples: value?.mma?.samples,
            mmas: value?.mma?.mmas,
        },
    }
}
export const formatLocations = (data: any): Array<LocationProps> => {
    return map(data, value => {
        const formattedLocations: LocationProps = formatLocation(value)

        return formattedLocations
    })
}

export const formatTask = (value: any) => {
    const formattedTask: TaskProps = {
        archived: value?.archived,
        assignees: value?.assigned ? formatUsers(value?.assigned) : [],
        audit: value?.audit,
        createdAt: new Date(value?.createdAt),
        createdBy: formatUser(value?.created_by),
        description: value?.description,
        dueDate: new Date(value.due_date),
        id: value?._id,
        image: value?.image,
        location: {
            id: value?.location?._id,
            name: value?.location?.name,
        },
        priority: value?.priority,
        status: value.status,
        taskStatus: value?.task_status,
        title: value?.task,
        area: {
            id: value?.area?._id,
            title: value?.area?.title,
        },
    }

    return formattedTask
}

export const formatTasks = (data: any): Array<TaskProps> => {
    return map(data, value => {
        return formatTask(value)
    })
}

export const formatUser = (data: any): UserProps => {
    return {
        acceptCookies: data.accept_cookies,
        device: data.device,
        defaultOrganization: formatLocation(data.defaultOrganization),
        email: data.email,
        firstname: data.first_name,
        id: data.id ? data.id : data._id,
        image: data?.image_url,
        internal: data.internal,
        isLoading: false,
        isLoggedIn: data.isLoggedIn,
        lastActive: data.last_active,
        lastname: data.last_name,
        language: data.language,
        notified: data.notified,
        organizations: formatLocations(data.organizations),
        role: String(data.role),
        status: data.status,
        subscription: {
            type: data?.subscription?.type,
            members: data?.subscription?.members,
            dateStarted: data?.subscription?.dateStarted
                ? new Date(data?.subscription?.dateStarted)
                : new Date(),
            dateEnd: data?.subscription?.dateEnd
                ? new Date(data?.subscription?.dateEnd)
                : new Date(),
            premiumGrant: data?.subscription?.premiumGrant,
        },
        tester: data.tester,
        theme: data.theme,
        username: data.username,
        verified: data.verified,
        createdAt: new Date(data.createdAt),
        issues: data.issues,
        userToken: data.userToken,
        userTokenExpires: new Date(data.userTokenExpires),
        allowUpdates: data.allowUpdates,
    }
}

export const formatUsers = (data: any): Array<UserProps> => {
    return map(data, value => {
        return formatUser(value)
    })
}

export const formatAuditScore = (data: any) => {
    return map(data, value => {
        const formattedAuditScore: AuditScoreProps = {
            id: value?._id,
            categoryId: value?.question?.category?._id,
            categoryName: value?.question?.category?.name,
            categoryDescription: value?.question?.category.description,
            comment: value?.comment,
            example: value?.question?.example,
            hasDeviation: value?.has_deviation,
            image: value?.image,
            maxPoints: value?.question?.max_points,
            order: value?.question?.order,
            question: value?.question?.question,
            score: value?.score,
        }

        return formattedAuditScore
    })
}

export const formatAudit = (value: any) => {
    const formattedReportPerArea: AuditProps = {
        id: value?._id,
        area: value?.area,
        createdAt: DateTime.fromISO(value.createdAt),
        createdBy: {
            id: value?.created_by?._id,
            email: value?.created_by?.email,
            firstname: value?.created_by?.first_name,
            lastname: value?.created_by?.last_name,
            defaultOrganization: value?.created_by?.defaultOrganization,
        },
        status: value.status,
        scores: formatAuditScore(value.scores),
        checklist: {
            code: value?.checklist?.code,
            id: value?.checklist?._id,
            isShort: value?.checklist?.is_short,
            language: value?.checklist?.language,
            name: value?.checklist?.name,
            status: value?.checklist?.status,
            type: value?.checklist?.type,
            version: value?.checklist?.version,
            standard: value?.checklist.standard,
        },
    }

    return formattedReportPerArea
}
export const formatAudits = (data: any): Array<AuditProps> => {
    return map(data, value => formatAudit(value))
}

export const logout = () => {
    localStorage.removeItem('token')
    window.location.reload()
}

export const getInitials = (user: UserProps) => {
    if (!user.firstname || !user.lastname) {
        return `${user.email.charAt(0)}`
    }
    return `${user.firstname.charAt(0)}${user.lastname.charAt(0)}`
}

export const getScore = ({
    scores,
    name,
}: {
    scores: Array<AuditScoreProps>
    name: string
}): { score: number; length: number; maxPoints: number } => {
    const filteredScore = filter(
        scores,
        ({ categoryName }) => categoryName === name,
    )
    const mappedScore = map(filteredScore, value => {
        return value.score
    })

    const finalScore = reduce(mappedScore, (prev, curr) => {
        return prev + curr
    })

    return {
        score: finalScore ? finalScore : 0,
        length: mappedScore ? mappedScore.length : 0,
        maxPoints: filteredScore[0]?.maxPoints,
    }
}

const reportReducer = (value: Array<DasboardReportDataProps>, name: string) => {
    const reduced = reduce(value, (prev, curr) => {
        const values: DasboardReportDataProps = {
            name,
            s1: prev.s1 + curr.s1,
            s2: prev.s2 + curr.s2,
            s3: prev.s3 + curr.s3,
            s4: prev.s4 + curr.s4,
            s5: prev.s5 + curr.s5,
            s6: prev.s6 + curr.s6,
        }
        return values
    })

    return {
        name: reduced?.name,
        s1: Math.round(reduced?.s1 ? reduced?.s1 / value.length : 0),
        s2: Math.round(reduced?.s2 ? reduced?.s2 / value.length : 0),
        s3: Math.round(reduced?.s3 ? reduced?.s3 / value.length : 0),
        s4: Math.round(reduced?.s4 ? reduced?.s4 / value.length : 0),
        s5: Math.round(reduced?.s5 ? reduced?.s5 / value.length : 0),
        s6: Math.round(reduced?.s6 ? reduced?.s6 / value.length : 0),
    }
}

const mapReportData = (report: any) => {
    return {
        name: report.createdAt.monthLong,
        year: report.createdAt.year,
        s1: getScore({ scores: report.scores, name: 'S1' }).score,
        s2: getScore({ scores: report.scores, name: 'S2' }).score,
        s3: getScore({ scores: report.scores, name: 'S3' }).score,
        s4: getScore({ scores: report.scores, name: 'S4' }).score,
        s5: getScore({ scores: report.scores, name: 'S5' }).score,
        s6: getScore({ scores: report.scores, name: 'S6' }).score,
    }
}

export const getReport = (
    data: Array<AuditProps>,
): Array<DasboardReportDataProps> | Array<any> => {
    const mappedReports = map(data, mapReportData)
    const groupedReports = groupBy(mappedReports, 'name')
    const reduceReports = map(groupedReports, reportReducer)

    console.log('-----------------')
    console.log('getReport() Breakdown:')
    console.log('mappedReports:', mappedReports)
    console.log('groupedReports:', groupedReports)
    console.log('reduceReports:', reduceReports)
    console.log('-----------------')
    return reduceReports
}

export const roundDataSixS = ({
    score,
    length,
    maxPoints,
}: {
    score: number
    length: number
    maxPoints: number
}) => {
    const roundedValue = round((score / (length * maxPoints)) * 100)
    return roundedValue ? roundedValue : 0
}

export const getStatusIcon = (status: string, png?: boolean) => {
    if (png) {
        switch (status) {
            case 'todo':
                return <img src={TodoIconPng} alt='todo' />
            case 'inprogress':
                return <img src={InProgressIconPng} alt='inprogress' />
            case 'toreview':
                return <img src={ToReviewIconPng} alt='toreview' />
            case 'done':
                return <img src={DoneIconPng} alt='done' />
        }
        return
    }
    switch (status) {
        case 'todo':
            return (
                <StatusIcon color={theme('light').colors.neutral002}>
                    <TodoIcon />
                </StatusIcon>
            )
        case 'inprogress':
            return (
                <StatusIcon color={theme('light').colors.warning}>
                    <InProgressIcon />
                </StatusIcon>
            )
        case 'toreview':
            return (
                <StatusIcon color={theme('light').colors.primary}>
                    <ToReviewIcon />
                </StatusIcon>
            )
        case 'done':
            return (
                <StatusIcon color={theme('light').colors.success}>
                    <DoneIcon />
                </StatusIcon>
            )
    }
}

const StatusIcon = styled.div<{ color: string }>`
    color: ${props => props.color};
    display: flex;
    align-items: center;
`

export const getPriorityIcon = (priority: string) => {
    switch (priority.toLowerCase()) {
        case 'low':
            return <LowIcon />
        case 'medium':
            return <MediumIcon />
        case 'high':
            return <HighIcon />
        case 'urgent':
        case 'safety':
            return <SafetyIcon />
    }
}

export const getWeekNumber = (date: any) => {
    const januaryOne: any = new Date(date.getFullYear(), 0, 1)
    const timeDiff = date - januaryOne
    const dayOfYear = Math.ceil(timeDiff / (24 * 60 * 60 * 1000))
    const weekNumber = Math.ceil(dayOfYear / 7)

    return weekNumber
}

export const getWeekDates = (weekNumber: string, year: number) => {
    const trueWeekNumber = Number(weekNumber.split('/')[0])
    const startDate = new Date(year, 0, 1 + (trueWeekNumber - 1) * 7)
    const endDate = new Date(startDate.getTime())
    endDate.setDate(endDate.getDate() + 6)

    const start = DateTime.fromJSDate(startDate).toMillis()
    const end = DateTime.fromJSDate(endDate).toMillis()

    return {
        start,
        end,
    }
}

export const getLanguage = (me: UserProps) => {
    const cookieLanguage = Cookie.get('lng')

    return me.isLoggedIn
        ? me.language
        : cookieLanguage
        ? cookieLanguage
        : navigator.language.substring(0, 2) === 'de'
        ? 'de'
        : 'en'
}

export const getAuditScore = (data: any): number => {
    return reduce(data, (prev, current) => {
        if (typeof prev === 'object') {
            return parseFloat(prev.score) + parseFloat(current.score)
        }

        return prev + parseFloat(current.score)
    })
}

export const getSubscriptionType = ({
    type,
    creatorId,
    meId,
}: {
    type: number
    creatorId?: string
    meId: string
}) => {
    if (creatorId !== meId) {
        return 'Enterprise Member'
    }
    switch (type) {
        case 1:
            return 'Monthly'
        case 2:
            return 'Yearly'
        case 3:
            return 'Enterprise'
        default:
            return 'Free'
    }
}

export const getAreaIcon = (value: AreaProps) => {
    switch (value.type.toLocaleLowerCase()) {
        case 'production':
            return <ProductionIcon />
        case 'office':
            return <OfficeIcon />
        case 'laboratory':
            return <LaboratoryIcon />
    }
}
