import React, { FC } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

export type LoaderProps = {
    isFit?: boolean
    isModal?: boolean
    height?: string
}

const Loader: FC<LoaderProps> = ({
    isFit = false,
    isModal = false,
    height,
}) => {
    return (
        <Wrapper isFit={isFit} isModal={isModal} height={height}>
            <Content>
                <Line delay={500} width={12}></Line>
                <Line delay={300} width={18}></Line>
                <Line delay={100} width={25}></Line>
            </Content>
        </Wrapper>
    )
}

const flicker = keyframes`
	0% {
		opacity: 1
	}
	50% {
		opacity: 0.25
	}
	100% {
		opacity: 1
	}
`

const Wrapper = styled.div<{
    isFit: boolean
    isModal: boolean
    height?: string
}>`
    align-items: center;
    display: flex;
    flex: 1;
    height: ${props =>
        props.isFit ? '100%' : props.height ? props.height : '100vh'};
    justify-content: center;
    width: 100%;
    ${props =>
        props.isModal &&
        css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: auto;
            height: 100%;
            background: ${props => props.theme.colors.neutral.c100};
            z-index: 100;
        `}
`

const Content = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: column;
`

const Line = styled.span<{ delay: number; width: number }>`
    animation: 1s ${flicker} ${props => props.delay}ms linear infinite;
    background: ${props => props.theme.colors.neutral.c500};
    display: flex;
    height: 5px;
    margin-bottom: 5px;
    margin-left: auto;
    margin-right: ${props => props.width / 3}px;
    width: ${props => props.width}px;
`

export default Loader
