import { createContext, Dispatch, FC, useContext, useReducer } from 'react'
import { reducer } from './reducer'
import { MeActionProps } from './types'
import { UserProps } from 'providers/Users/types'

export const initialMeState: UserProps = {
    allowUpdates: false,
    acceptCookies: false,
    device: '',
    defaultOrganization: {
        createdAt: '',
        createdBy: {},
        description: '',
        id: '',
        is6s: false,
        image: '',
        name: '',
        status: 0,
        members: [],
        targetScore: 75,
        taskOrder: [],
        audit: {
            tasks: 0,
            audits: 0,
            areas: 0,
        },
        mma: {
            default: '',
            shifts: [],
            samples: 0,
            mmas: 0,
        },
    },
    email: '',
    firstname: '',
    id: '',
    image: '',
    internal: false,
    isLoading: true,
    isLoggedIn: false,
    language: 'en',
    lastActive: new Date(),
    lastname: '',
    notified: false,
    organizations: [],
    role: '0',
    status: '1',
    subscription: {
        type: 0,
        members: 0,
        dateStarted: new Date(),
        dateEnd: new Date(),
        premiumGrant: 'official',
    },
    tester: false,
    theme: 'light',
    username: '',
    verified: false,
    createdAt: new Date(),
    issues: '',
    userToken: '',
    userTokenExpires: new Date(),
}

export const MeState = createContext<UserProps>(initialMeState)
export const MeDispatch = createContext<Dispatch<MeActionProps>>(() => null)
export const useMeState = () => useContext(MeState)
export const useMeDispatch = () => useContext(MeDispatch)

export const MeProvider: FC = ({ children }) => {
    const [userState, userDispatch] = useReducer(
        reducer,
        initialMeState,
        () => initialMeState,
    )

    return (
        <MeState.Provider value={userState}>
            <MeDispatch.Provider value={userDispatch}>
                {children}
            </MeDispatch.Provider>
        </MeState.Provider>
    )
}
