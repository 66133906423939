import { LocationProps } from 'providers/Locations/types'
import { UserProps } from 'providers/Users/types'

export enum WorkspaceActions {
    add = 'ADD',
    fetch = 'FETCH',
    remove = 'REMOVE',
    update = 'UPDATE',
    updateLoading = 'UPDATE_LOADING',
    updateCount = 'UPDATE_COUNT',
    addPoint = 'ADD_POINT',
    updatePoint = 'UPDATE_POINT',
    updatePointPosition = 'UPDATE_POINT_POSITION',
    removePoint = 'REMOVE_POINT',
    addKeyEvent = 'ADD_KEY_EVENT',
    updateKeyEvent = 'UPDATE_KEY_EVENT',
    removeKeyEvent = 'REMOVE_KEY_EVENT',
}

export type PointProps = {
    id: string
    createdAt: string
    description: string
    name: string
}

export type KeyEventProps = {
    id: string
    date: string
    description: string
    name: string
}

export type ActivityProps = {
    id: string
    description: string
    name: string
    color: string
    isDefault: boolean
}

export type WorkspaceProps = {
    id: string
    location: Partial<LocationProps> | string
    createdAt: string
    createdBy: Partial<UserProps> | string
    description: string
    name: string
    points: Array<PointProps>
    events: Array<KeyEventProps>
    activities: Array<ActivityProps>
}

export type WorkspacesStateProps = {
    data: Array<WorkspaceProps>
    isLoading: boolean
}

export type WorkspaceActionProps =
    | { type: WorkspaceActions.add; payload: WorkspaceProps }
    | { type: WorkspaceActions.fetch; payload: Array<WorkspaceProps> }
    | { type: WorkspaceActions.remove; payload: string }
    | { type: WorkspaceActions.update; payload: WorkspaceProps }
    | { type: WorkspaceActions.updateLoading; payload: boolean }
    | { type: WorkspaceActions.updateCount; payload: number }
    | {
          type: WorkspaceActions.addPoint
          payload: { id: string; point: PointProps }
      }
    | {
          type: WorkspaceActions.updatePoint
          payload: { workspaceId: string; PointId: string; point: PointProps }
      }
    | {
          type: WorkspaceActions.updatePointPosition
          payload: {
              workspaceId: string
              points: Array<PointProps>
          }
      }
    | {
          type: WorkspaceActions.removePoint
          payload: { workspaceId: string; PointId: string }
      }
    | {
          type: WorkspaceActions.addKeyEvent
          payload: { id: string; keyEvent: KeyEventProps }
      }
    | {
          type: WorkspaceActions.updateKeyEvent
          payload: {
              workspaceId: string
              KeyEventId: string
              keyEvent: KeyEventProps
          }
      }
    | {
          type: WorkspaceActions.removeKeyEvent
          payload: { workspaceId: string; keyEventId: string }
      }
