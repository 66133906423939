import { ReactComponent as TimesIcon } from 'icons/times.svg'
import { lighten, transparentize } from 'polished'
import { ToastContextMessageProps, ToastTypes } from 'providers/Toast'
import React, { FC, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

export type ToastMessageProps = {
    message: ToastContextMessageProps
    onClose: () => void
    onTimerEnd: () => void
}

const Message: FC<ToastMessageProps> = ({ message, onClose, onTimerEnd }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            onTimerEnd()
        }, message.timer)

        return () => {
            clearTimeout(timer)
        }
    }, [message.timer, onTimerEnd])

    return (
        <Wrapper variant={message?.type}>
            <Text>{message.text}</Text>
            <Close onClick={onClose} variant={message?.type}>
                <TimesIcon />
            </Close>
        </Wrapper>
    )
}

const slideUp = keyframes`
	from {
		opacity: 0;
		transform: translateY(50px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
`

const Wrapper = styled.div<{ variant: string | undefined }>`
    animation: ${slideUp} 250ms ease-in-out;
    background: ${props => props.theme.colors.brand.c500};
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.brand.c300};
    box-shadow: ${props => props.theme.shadows.wide};
    display: flex;
    font-size: ${props => props.theme.font.sizes.normal};
    font-weight: 400;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
    padding: 10px 10px 10px 20px;
    position: relative;
    transition: all 250ms ease-in-out;
    z-index: 1000;
    max-width: 320px;
    ${props => {
        switch (props.variant) {
            case ToastTypes.danger:
                return css`
                    background: ${props => props.theme.colors.danger.c500};
                    border-color: ${props => props.theme.colors.danger.c300};
                    color: ${props => props.theme.colors.neutral.c100};
                `

            case ToastTypes.warning:
                return css`
                    background: ${props => props.theme.colors.warning.c500};
                    border-color: ${props => props.theme.colors.warning.c300};
                    color: ${props => props.theme.colors.neutral.c100};
                `
        }
    }}
`

const Text = styled.div`
    color: #fff;
    margin-right: 20px;
    font-size: 14px;
    line-height: 21px;
`

const Close = styled.button<{ variant: string | undefined }>`
    background: transparent;
    border-radius: 30px;
    border: none;
    color: #fff;
    cursor: pointer;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 5px;
    right: 5px;
    pointer-events: all;
    &:hover {
        background: ${props =>
            transparentize(0.75, props.theme.colors.neutral.c100)};
        opacity: 0.75;
    }
`

export default Message
