import { map } from 'lodash'
import { FC, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components/macro'

import {
    ToastActions,
    ToastContextMessageProps,
    useToastDispatch,
    useToastState,
} from 'providers/Toast'
import Message from './Message'

const Toast: FC = () => {
    const { messages } = useToastState()
    const toastDispatch = useToastDispatch()
    const portal = document.getElementById('toastPortal') as HTMLElement

    const closeMessage = useCallback(
        (message: ToastContextMessageProps) => {
            toastDispatch({ type: ToastActions.remove, payload: message.id })
        },
        [toastDispatch],
    )
    const ToastComponent = (() => {
        return (
            <Wrapper>
                <MessagesWrapper>
                    {map(messages, (message, index) => (
                        <Message
                            key={index}
                            message={message}
                            onClose={() => closeMessage(message)}
                            onTimerEnd={() => closeMessage(message)}
                        />
                    ))}
                </MessagesWrapper>
            </Wrapper>
        )
    })()

    return createPortal(ToastComponent, portal)
}

const Wrapper = styled.div`
    background: transparent;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    z-index: 10002;
    pointer-events: none;
`

const MessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-end;
`

export default Toast
