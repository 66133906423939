import GlobalStyle from 'GlobalStyle'
import i18next from 'i18next'
import { isNil } from 'lodash'
import React, { Suspense, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { I18nextProvider } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components/macro'

import AdminElement from 'components/AdminElement'
import CookieNotice from 'components/CookieNotice'
import Loader from 'components/Loader'
import PrivateElement from 'components/PrivateElement'
import PublicOnlyElement from 'components/PublicOnlyElement'
import Toast from 'components/Toast'
import AppLink from 'pages/AppLink'

import { AnalysesProvider } from 'providers/Analyses'
import { AreasProvider } from 'providers/Areas'
import { AuditsProvider } from 'providers/Audits'
import { LocationsProvider } from 'providers/Locations'
import { useMeState } from 'providers/Me'
import { useFetchMe } from 'providers/Me/useFetchMe'
import { TasksProvider } from 'providers/Tasks'
import { ToastProvider } from 'providers/Toast'
import { UsersProvider } from 'providers/Users'

import theme from 'theme'
import { getLanguage } from 'utils/helpers'
import axios from 'axios'
import { WorkspacesProvider } from 'providers/Workspaces'

//MMA
const mmaPages = 'modules/mma/pages'
const MMA = React.lazy(() => import(`${mmaPages}/Dashboard`))
const MMAEdit = React.lazy(() => import(`${mmaPages}/MMA/Edit`))
const MMAAdd = React.lazy(() => import(`${mmaPages}/MMA/Add`))
const KeyEvents = React.lazy(() => import(`${mmaPages}/KeyEvents`))
const KeyEvent = React.lazy(() => import(`${mmaPages}/KeyEvents/KeyEvent`))
const Activities = React.lazy(() => import(`${mmaPages}/Activities`))
const Activity = React.lazy(() => import(`${mmaPages}/Activities/Activity`))
const ObservationPoints = React.lazy(
    () => import(`${mmaPages}/ObservationPoints`),
)
const ObservationPoint = React.lazy(
    () => import(`${mmaPages}/ObservationPoints/ObservationPoint`),
)
const Reports = React.lazy(() => import(`${mmaPages}/Reports`))
const Results = React.lazy(() => import(`${mmaPages}/Results`))

//Audit
const auditPages = 'modules/audit/pages'
const Areas = React.lazy(() => import(`${auditPages}/Areas`))
const AuditDetails = React.lazy(() => import(`${auditPages}/Audits/Details`))
const Audits = React.lazy(() => import(`${auditPages}/Audits`))
const Dashboard = React.lazy(() => import(`${auditPages}/Dashboard`))
const Locations = React.lazy(() => import(`${auditPages}/Locations`))
const ReportShare = React.lazy(() => import(`${auditPages}/ReportShare`))
const Share = React.lazy(() => import(`${auditPages}/ReportShare/Share`))
const Tasks = React.lazy(() => import(`${auditPages}/Tasks`))
const Verify = React.lazy(() => import(`${auditPages}/Verify`))

//admins
const adminPages = 'modules/admin/pages'
const Importer = React.lazy(() => import(`${adminPages}/Importer`))
const Organizations = React.lazy(() => import(`${adminPages}/Locations`))
const Questions = React.lazy(() => import(`${adminPages}/Questions`))
const QuestionDetails = React.lazy(
    () => import(`${adminPages}/Questions/Details`),
)
const Users = React.lazy(() => import(`${adminPages}/Users`))

//common
const Account = React.lazy(() => import(`pages/Profile/Account`))
const AccountVerification = React.lazy(
    () => import(`pages/ForgotPassword/AccountVerification`),
)
const EmailVerification = React.lazy(
    () => import(`pages/ForgotPassword/CodeVerification`),
)
const FourOhFour = React.lazy(() => import(`pages/FourOhFour`))
const Login = React.lazy(() => import(`pages/Login`))
const Organization = React.lazy(() => import(`pages/Profile/Organization`))
const Password = React.lazy(() => import(`pages/Profile/Password`))
const Personal = React.lazy(() => import(`pages/Profile/Personal`))
const Profile = React.lazy(() => import(`pages/Profile`))
const Me = React.lazy(() => import(`pages/Profile/Me`))
const ResetPassword = React.lazy(
    () => import(`pages/ForgotPassword/UpdatePassword`),
)
const Signup = React.lazy(() => import(`pages/Signup`))

ReactGA.initialize(`${process.env.GOOGLE_ANALYTICS_TRACKING_ID}`)

const App = () => {
    const me = useMeState()
    const lng = getLanguage(me)
    const [en, setEn] = useState<any>()
    const [de, setDe] = useState<any>()

    useFetchMe()

    useEffect(() => {
        const fn = async () => {
            const en = await axios.get('/translations/en/common.json')
            const de = await axios.get('/translations/de/common.json')

            setEn(en?.data)
            setDe(de?.data)
        }
        fn()
    }, [])

    useEffect(() => {
        window.localStorage.setItem('lng', lng)

        const fn = async () => {
            await i18next.init({
                interpolation: { escapeValue: false },
                returnEmptyString: false,
                lng,
                resources: {
                    en: {
                        common: en,
                    },
                    de: {
                        common: de,
                    },
                },
                react: {
                    useSuspense: true,
                },
                fallbackLng: 'en',
            })
        }

        fn()
    }, [en, de, me])

    return (
        <ThemeProvider theme={theme(!isNil(me.theme) ? me.theme : 'light')}>
            <GlobalStyle />
            <I18nextProvider i18n={i18next}>
                <ToastProvider>
                    <main className='App'>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                {/* public routes */}
                                <Route
                                    path='/login'
                                    element={
                                        <PublicOnlyElement>
                                            <Login />
                                        </PublicOnlyElement>
                                    }
                                />
                                <Route
                                    path='/signup'
                                    element={
                                        <PublicOnlyElement>
                                            <Signup />
                                        </PublicOnlyElement>
                                    }
                                />
                                {/* public routes */}

                                {/* public/private routes */}
                                <Route
                                    path='/'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <AuditsProvider>
                                                    <TasksProvider>
                                                        <Dashboard />
                                                    </TasksProvider>
                                                </AuditsProvider>
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <MMA />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />

                                <Route
                                    path='/mma/add/:id/:page'
                                    element={
                                        <PrivateElement>
                                            <MMAAdd />
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id'
                                    element={
                                        <PrivateElement>
                                            <MMAEdit />
                                        </PrivateElement>
                                    }
                                />

                                <Route
                                    path='/mma/edit/:id/observation-points'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <ObservationPoints />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/observation-point/add'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <ObservationPoint />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/observation-point/:pointId'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <ObservationPoint />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/activities'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <Activities />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/activities/add'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <Activity />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/activities/:activityId'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <Activity />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/key-events'
                                    element={
                                        <PrivateElement>
                                            <KeyEvents />
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/key-events/add'
                                    element={
                                        <PrivateElement>
                                            <KeyEvent />
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/edit/:id/key-events/:keyEventId'
                                    element={
                                        <PrivateElement>
                                            <KeyEvent />
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/results'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <Results />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/mma/reports'
                                    element={
                                        <PrivateElement>
                                            <AnalysesProvider>
                                                <Reports />
                                            </AnalysesProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/email-verification'
                                    element={<EmailVerification />}
                                />
                                <Route
                                    path='/forgot-password'
                                    element={<AccountVerification />}
                                />
                                <Route
                                    path='/reset-password/:token'
                                    element={<ResetPassword />}
                                />

                                <Route
                                    path='/organizations'
                                    element={
                                        <PrivateElement>
                                            <Locations />
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/areas'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <Areas />
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/areas/:areaId'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <Areas />
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route path='/audits'>
                                    <Route
                                        path=''
                                        element={
                                            <PrivateElement>
                                                <AreasProvider>
                                                    <AuditsProvider>
                                                        <Audits />
                                                    </AuditsProvider>
                                                </AreasProvider>
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path=':id'
                                        element={
                                            <AuditsProvider>
                                                <AuditDetails />
                                            </AuditsProvider>
                                        }
                                    />
                                </Route>
                                <Route
                                    path='/tasks'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <TasksProvider>
                                                    {!me.isLoading && <Tasks />}
                                                </TasksProvider>
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/tasks/add'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <TasksProvider>
                                                    {!me.isLoading && <Tasks />}
                                                </TasksProvider>
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/tasks/:taskId'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <TasksProvider>
                                                    {!me.isLoading && <Tasks />}
                                                </TasksProvider>
                                            </AreasProvider>
                                        </PrivateElement>
                                    }
                                />
                                <Route
                                    path='/r'
                                    element={
                                        <PrivateElement>
                                            <AreasProvider>
                                                <AuditsProvider>
                                                    <TasksProvider>
                                                        <ReportShare />
                                                    </TasksProvider>
                                                </AuditsProvider>
                                            </AreasProvider>
                                        </PrivateElement>
                                    }>
                                    <Route
                                        path=':areaId/:locationId/:standard'
                                        element={<Share />}
                                    />
                                </Route>
                                <Route
                                    path='/:id'
                                    element={
                                        <PrivateElement>
                                            <Profile />
                                        </PrivateElement>
                                    }>
                                    <Route
                                        path='me'
                                        element={
                                            <PrivateElement>
                                                <AreasProvider>
                                                    <TasksProvider>
                                                        <WorkspacesProvider>
                                                            <Me />
                                                        </WorkspacesProvider>
                                                    </TasksProvider>
                                                </AreasProvider>
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='personal'
                                        element={
                                            <PrivateElement>
                                                <Personal />
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='account'
                                        element={
                                            <PrivateElement>
                                                <Account />
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='password'
                                        element={
                                            <PrivateElement>
                                                <Password />
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='organization'
                                        element={
                                            <PrivateElement>
                                                <Organization />
                                            </PrivateElement>
                                        }
                                    />
                                </Route>
                                <Route path='/settings'>
                                    <Route
                                        path='personal'
                                        element={
                                            <PrivateElement>
                                                <Navigate
                                                    to={`/${me.id}/personal`}
                                                />
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='account'
                                        element={
                                            <PrivateElement>
                                                <Navigate
                                                    to={`/${me.id}/account`}
                                                />
                                            </PrivateElement>
                                        }
                                    />
                                    <Route
                                        path='password'
                                        element={
                                            <PrivateElement>
                                                <Navigate
                                                    to={`/${me.id}/password`}
                                                />
                                            </PrivateElement>
                                        }
                                    />
                                </Route>
                                <Route
                                    path='verify/:token'
                                    element={<Verify />}
                                />
                                {/* private routes */}

                                {/* private/admins pages */}
                                <Route
                                    path='/admin/importer'
                                    element={
                                        <AdminElement>
                                            <Importer />
                                        </AdminElement>
                                    }
                                />
                                <Route
                                    path='/admin/organizations'
                                    element={
                                        <AdminElement>
                                            <Organizations />
                                        </AdminElement>
                                    }
                                />
                                <Route
                                    path='/admin/questions'
                                    element={
                                        <AdminElement>
                                            <Questions />
                                        </AdminElement>
                                    }
                                />
                                <Route
                                    path='/admin/questions/:checklistId'
                                    element={
                                        <AdminElement>
                                            <QuestionDetails />
                                        </AdminElement>
                                    }
                                />
                                <Route path='*' element={<FourOhFour />} />
                                <Route
                                    path='/admin/users'
                                    element={
                                        <AdminElement>
                                            <UsersProvider>
                                                <Users />
                                            </UsersProvider>
                                        </AdminElement>
                                    }
                                />
                                <Route
                                    path='/admin/users/:userId'
                                    element={
                                        <AdminElement>
                                            <UsersProvider>
                                                <Users />
                                            </UsersProvider>
                                        </AdminElement>
                                    }
                                />
                                <Route
                                    path='/goleansigmaapp'
                                    element={<AppLink />}
                                />
                            </Routes>
                            <Toast /> {/* toast notification */}
                            <CookieNotice /> {/* cookie notice */}
                        </Suspense>
                    </main>
                </ToastProvider>
            </I18nextProvider>
        </ThemeProvider>
    )
}

export default App
