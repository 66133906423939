import api from 'api'
import { CancelTokenSource } from 'axios'
import { isNil } from 'lodash'
import { UserProps } from 'providers/Users/types'
import { getHeaders } from 'utils/helpers'

export const getProfile = ({ source }: { source: CancelTokenSource }) => {
    return api.get('/user/profile/me', {
        cancelToken: source.token,
        headers: getHeaders(),
    })
}

export const updateProfile = ({
    acceptCookies,
    currentPassword,
    defaultOrganization,
    email,
    firstname,
    language,
    lastname,
    newPassword,
    role,
    status,
    theme,
    username,
    verified,
    issues,
    allowUpdates,
}: Partial<
    Omit<UserProps, 'defaultOrganization'> & { defaultOrganization: string }
>) => {
    const defaultConfig = {
        accept_cookies: acceptCookies,
        current_password: currentPassword,
        defaultOrganization,
        email,
        first_name: firstname,
        language,
        last_name: lastname,
        role,
        status,
        issues,
        theme,
        username,
        verified,
        allowUpdates,
    }

    const config =
        isNil(currentPassword) && isNil(newPassword)
            ? defaultConfig
            : {
                  ...defaultConfig,
                  new_password: newPassword,
              }
    return api.put('/user/profile/update', {
        ...config,
        headers: getHeaders(),
    })
}
