import { UserProps } from 'providers/Users/types'
import { ActivityProps } from 'providers/Workspaces/types'

export enum LocationsActions {
    add = 'ADD',
    fetch = 'FETCH',
    remove = 'REMOVE',
    update = 'UPDATE',
    updateCount = 'UPDATE_COUNT',
    updateLoading = 'UPDATE_LOADING',
    updateTaskOrder = 'UPDATE_TASK_ORDER',
    updateDefaultMMA = 'UPDATE_DEFAULT_MMA',
}

export type LocationsActionProps =
    | { type: LocationsActions.add; payload: LocationProps }
    | { type: LocationsActions.fetch; payload: LocationProps[] }
    | { type: LocationsActions.remove; payload: string }
    | { type: LocationsActions.update; payload: LocationProps }
    | { type: LocationsActions.updateCount; payload: number }
    | { type: LocationsActions.updateLoading; payload: boolean }
    | { type: LocationsActions.updateDefaultMMA; payload: string }
    | {
          type: LocationsActions.updateTaskOrder
          payload: Pick<LocationProps, 'taskOrder'>
      }

export type LocationShiftProps = {
    id: string
    name: string
    start: number
    end: number
}

export type LocationProps = {
    createdAt: string
    createdBy: Partial<UserProps>
    description: string
    id: string
    is6s: boolean
    members: Array<Partial<UserProps>>
    name: string
    status: number
    targetScore: number
    taskOrder: Array<string>
    image: string
    audit: {
        tasks: number
        areas: number
        audits: number
    }
    mma: {
        default: string
        shifts: Array<LocationShiftProps>
        mmas: number
        samples: number
    }
}

export type LocationsStateProps = {
    data: Array<LocationProps>
    isLoading: boolean
    count: number
}
