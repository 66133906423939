import { DateTime } from 'luxon'

import { ActivityProps, PointProps } from 'providers/Workspaces/types'

export enum AnalysisActions {
    add = 'ADD',
    fetch = 'FETCH',
    remove = 'REMOVE',
    update = 'UPDATE',
    updateLoading = 'UPDATE_LOADING',
    updateCount = 'UPDATE_COUNT',
}

export type AnalysisScoreProps = {
    point: Partial<PointProps>
    category: Partial<ActivityProps>
    score: number
}

export type AnalysisProps = {
    id: string
    date: DateTime
    createdAt: DateTime
    createdBy: string
    workspace: string
    location: string
    scores: Array<AnalysisScoreProps>
    shift?: string
}

export type AnalysesStateProps = {
    data: Array<AnalysisProps>
    isLoading: boolean
}

export type AnalysisActionProps =
    | { type: AnalysisActions.add; payload: AnalysisProps }
    | { type: AnalysisActions.fetch; payload: Array<AnalysisProps> }
    | { type: AnalysisActions.remove; payload: string }
    | { type: AnalysisActions.update; payload: AnalysisProps }
    | { type: AnalysisActions.updateLoading; payload: boolean }
    | { type: AnalysisActions.updateCount; payload: number }
