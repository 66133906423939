import { createGlobalStyle } from 'styled-components/macro'
import { ThemeType } from 'theme'

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  * {
    border: 0;
    /* border: 1px dashed red; */
    box-sizing: border-box;
    font-family: ${props => props.theme.font.family.default};
    margin: 0;
    @media screen and (min-width: 992px) {
      font-size: 16px;
    }
  }

  html,
  body {
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.colors.neutral.c100};
    color: ${props => props.theme.colors.neutral.c900};
    font-family: ${props => props.theme.font.family.default};
    font-size: 100%;
    line-height: 1.7em;
    min-height: calc(100vh - 60px);
    scroll-behavior: smooth;
  }

  #root, .App {
    min-height: 100vh;
  }

  a {
    font-size: inherit;
    color: ${props => props.theme.colors.brand.c500};
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }

  p, li {
    color: ${props => props.theme.colors.neutral.c500};
    font-size: ${props => props.theme.font.sizes.medium};
  }
  
  hr {
    border-bottom: 1px solid ${props => props.theme.colors.neutral.c300};
  }
`

export default GlobalStyle
