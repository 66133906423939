import { filter, map } from 'lodash'
import {
    WorkspaceActionProps,
    WorkspaceActions,
    WorkspacesStateProps,
} from './types'

const reducer = (state: WorkspacesStateProps, action: WorkspaceActionProps) => {
    switch (action.type) {
        case WorkspaceActions.fetch:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            }

        case WorkspaceActions.add:
            return {
                ...state,
                data: [action.payload, ...state.data],
                isLoading: false,
            }

        case WorkspaceActions.remove:
            return {
                ...state,
                data: filter(state.data, value => value?.id !== action.payload),
                isLoading: false,
            }

        case WorkspaceActions.update:
            return {
                ...state,
                data: map(state.data, value => {
                    if (value?.id === action.payload?.id) {
                        return {
                            ...value,
                            description: action.payload?.description,
                            name: action.payload?.name,
                            points: action.payload?.points,
                            events: action.payload?.events,
                            activities: action.payload?.activities,
                        }
                    }

                    return value
                }),
                isLoading: false,
            }

        case WorkspaceActions.updateLoading:
            return {
                ...state,
                isLoading: action.payload,
            }

        case WorkspaceActions.updateCount:
            return {
                ...state,
                count: action.payload,
            }

        case WorkspaceActions.addPoint:
            return {
                ...state,
                data: map(state.data, value => {
                    if (value?.id === action.payload?.id) {
                        return {
                            ...value,
                            points: [action.payload?.point, ...value.points],
                        }
                    }

                    return value
                }),
                isLoading: false,
            }
        case WorkspaceActions.updatePointPosition:
            return {
                ...state,
                data: map(state.data, value => {
                    if (value?.id === action.payload?.workspaceId) {
                        return {
                            ...value,
                            points: action.payload?.points,
                        }
                    }

                    return value
                }),
                isLoading: false,
            }
        default:
            return state
    }
}

export default reducer
